<template>
  <div
    class="garage-opinion"
    itemprop="review"
    itemscope
    itemtype="http://schema.org/Review"
  >
    <div class="garage-opinion__header">
      <div class="garage-opinion__header-wrapper">
        <div class="garage-opinion__header-logo">{{ garageOpinion.name?.substring(0, 1).toLocaleUpperCase() }}</div>
        <div>
          <div
            class="garage-opinion__author"
            itemprop="author"
          >{{ garageOpinion.name }}</div>
          <div
            class="garage-opinion__created-at"
            itemprop="datePublished"
          >{{ dateFormat(garageOpinion?.created) }}</div>
        </div>
      </div>

      <div
        @click="expandableContent.rating = !expandableContent.rating"
        class="garage-opinion__header-rating"
        itemprop="reviewRating"
        itemscope
        itemtype="http://schema.org/Rating"
      >
        <meta
          itemprop="worstRating"
          content="0"
        />
        <meta
          itemprop="bestRating"
          content="6"
        />
        <meta
          itemprop="ratingValue"
          :content="`${garageOpinion.ratingTotal}`"
        />
        <div class="garage-opinion__rating-stars">
          <NuxtImg
            v-for="(item, index) in 6"
            alt="Ocena DobryMechanik.pl"
            width="18px"
            height="18px"
            class="garage-opinion__rating-star"
            :src="getStar(garageOpinion.ratingTotal || 0, index).toString()"
          />
        </div>
        <i
          class="i-arrow-down"
          :class="{ 'rotated-icon': expandableContent.rating }"
        ></i>
      </div>
    </div>

    <div
      v-show="expandableContent.rating"
      class="garage-opinion__rating-details"
    >
      <div class="garage-opinion__rating-detail">
        <p>Czas naprawy:</p>
        <div class="garage-opinion__rating-stars">
          <NuxtImg
            v-for="(item, index) in 6"
            alt="Ocena DobryMechanik.pl"
            width="18px"
            height="18px"
            class="garage-opinion__rating-star"
            :src="getStar(garageOpinion.ratingTime || 0, index).toString()"
          />
        </div>
      </div>
      <div class="garage-opinion__rating-detail">
        <p>Cena usługi:</p>
        <div class="garage-opinion__rating-stars">
          <NuxtImg
            v-for="(item, index) in 6"
            alt="Ocena DobryMechanik.pl"
            width="18px"
            height="18px"
            class="garage-opinion__rating-star"
            :src="getStar(garageOpinion.ratingPrice || 0, index).toString()"
          />
        </div>
      </div>
      <div class="garage-opinion__rating-detail">
        <p>Jakość usługi:</p>
        <div class="garage-opinion__rating-stars">
          <NuxtImg
            v-for="(item, index) in 6"
            alt="Ocena DobryMechanik.pl"
            width="18px"
            height="18px"
            class="garage-opinion__rating-star"
            :src="getStar(garageOpinion.ratingService || 0, index).toString()"
          />
        </div>
      </div>
    </div>

    <p class="garage-opinion__car">
      Pojazd: {{ garageOpinion?.car || '-' }}
    </p>

    <p
      v-if="showGarageName && garageOpinion"
      class="garage-opinion__link"
    >
      Warsztat: <NuxtLink
        target="_blank"
        :to="(garageOpinion as any).url"
      >{{ garageOpinion.garage?.name }}</NuxtLink>
    </p>

    <div
      v-if="!!garageOpinion?.trusted"
      class="garage-opinion__arranged-by-dm"
    >
      <NuxtImg
        src="/_nuxt/checkCircle.svg"
        alt="Ocena DobryMechanik.pl"
        width="20px"
        height="20px"
        class="mr-1"
      />Wizyta umówiona przez DobryMechanik.pl
    </div>

    <div
      class="garage-opinion__description"
      itemprop="description"
    >
      <p>
        {{ opinionDescription }}
        <span
          @click="expandableContent.description = !expandableContent.description"
          class="garage-opinion__description-show-more"
          v-if="(garageOpinion.description?.length || 0) > shortDescriptionLength"
        >{{ expandableContent.description ? 'Zwiń' : 'Rozwiń' }}
        </span>
      </p>
    </div>

    <div
      v-if="garageOpinion.answers?.length"
      class="garage-opinion__answers"
    >
      <h5>Odpowiedź warsztatu:</h5>
      <p
        class="garage-opinion__answer"
        v-for="answer in garageOpinion.answers"
      >
        {{ answer.content }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script
  setup
  lang="ts"
>
import { getStar } from '@/helpers/get-star';
import { dateFormat } from '~/helpers/dayjs';
import type { GarageCommentJsonldGarageReadIdGarageReadNameCommentReadAnswerRead } from '~/api/docs/api';

interface IProps {
  garageOpinion: GarageCommentJsonldGarageReadIdGarageReadNameCommentReadAnswerRead;
  showGarageName?: boolean;
}

// data
const expandableContent: Ref<any> = ref({
  rating: false,
  description: false,
  answer: false
});
const shortDescriptionLength: number = 140;

// props
const props = defineProps<IProps>();

// computed
const opinionDescription = computed(() => {
  return props.garageOpinion.description
    ? !expandableContent.value.description && props.garageOpinion.description.length > shortDescriptionLength
      ? props.garageOpinion.description.substring(0, shortDescriptionLength) + '...'
      : props.garageOpinion.description
    : null;
});
</script>

<style lang="scss">
@import url('assets/styles/components/garage-opinion-element.scss');
</style>
